<template>
    <el-dialog :visible.sync="formDialog" title="详情" @close="closeForm">
        <el-form ref="dataForm" :model="dataForm" label-width="100px" class="data-form">
            <el-form-item label="ID">
                <p>{{ dataForm.id }}</p>
            </el-form-item>
            <el-form-item label="名称">
                <p>{{ dataForm.name }}</p>
            </el-form-item>
            <el-form-item label="简介">
                <p>{{ dataForm.intro }}</p>
            </el-form-item>
        </el-form>
    </el-dialog>
</template>

<script>
import { getMealInfo } from '@/utils/servers/meal.js';
export default {
    props: ['viewFormDialog', 'viewFormId'],
    data() {
        return {
            formDialog: this.viewFormDialog,
            dataForm: {}
        };
    },
    created() {
        this.getMealInfo();
    },
    methods: {
        getMealInfo() {
            getMealInfo(this.viewFormId).then(res => {
                var data = res;
                if (data.code == 1) {
                    this.dataForm = data.data;
                } else {
                    this.$message.error(data.msg);
                }
            });
        },
        closeForm() {
            this.$emit('closeForm');
        }
    }
};
</script>

<style lang="less" scoped>
.data-form {
    width: 400px;
    .avatar-uploader .el-upload {
        border: 1px dashed #d9d9d9;
        border-radius: 6px;
        cursor: pointer;
        position: relative;
        overflow: hidden;
    }
    .avatar-uploader .el-upload:hover {
        border-color: #409eff;
    }
    .avatar-uploader-icon {
        font-size: 28px;
        color: #8c939d;
        width: 178px;
        height: 178px;
        line-height: 178px;
        text-align: center;
    }
    .avatar {
        width: 178px;
        height: 178px;
        display: block;
    }
}
</style>
