import request from '@/utils/request';

export function getMealList(data) {
    return request({
        url: '/meal/list',
        method: 'post',
        data: data
    });
}

export function getMealInfo(id) {
    return request({
        url: '/meal/info/' + id,
        method: 'get',
    });
}

export function delMeal(data) {
    return request({
        url: '/meal/del',
        method: 'post',
        data: data
    });
}
